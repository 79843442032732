import React, { Component } from "react";
import Header from "../components/Header";
import ChatBot from "@optimlx/villachat";
import optLogo  from '../assets/img/optlogo.png'
var __html = require('./index.html.js');
var template = { __html: __html };



class VillaChatAgent extends Component {

 

  render() {
    return (
      <><Header /><><div align="center">
        
        <a href="https://optimlx.com" target="_blank">
        </a>
      </div><h1 align="left"><font color="#00008B">~~~ VillaChat ~~~</font></h1><div className="card">
          <p align="left">
            Please try our AI enabled chat to see how it can serve your business.
          </p>
        </div><ChatBot message={{client_id:"100001"}}/></></>
    
    );
  }
}
export default VillaChatAgent;