import React, { Component } from "react";
import Header from "../components/Header";
import ChatBot from "@optimlx/villachat";
import optLogo  from '../assets/img/optlogo.png'
var __html = require('./index.html.js');
var template = { __html: __html };



class VillaChatAgent extends Component {

 

  render() {
    return (
      <><><div align="center">
    </div><ChatBot message={{client_id:"100001"}}/></></>
    
    );
  }
}
export default VillaChatAgent;