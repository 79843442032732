import React, { Component } from "react";
import Header from "../components/Header/index.js";
import ChatBot from "@optimlx/villachat";
import optLogo  from '../assets/img/optlogo.png'
var __html = require('./index.html.js');
var template = { __html: __html };



class stjohnvillaPP extends Component {

 

  render() {
    return (
      <><><div align="center">
        
      </div><h1 align="left"><font color="#00008B">Privacy Policy for St Johns Resort Villas Chatbot</font></h1><div className="card">
          
      

      <b>Effective Date: August 1, 2024</b>

At St Johns Resort Villas, we are committed to protecting your privacy and ensuring a safe, secure, and enjoyable experience with our chatbot. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our chatbot on our website. By engaging with the chatbot, you consent to the practices described in this policy. If you do not consent, you are forbidden from using this chatbot!

<b>1. Information We Collect</b>

When you interact with our chatbot, we may collect the following information:

    Chat Content: We record the content of your conversation with our chatbot for quality control purposes only. This includes any questions, responses, or feedback you provide to improve the accuracy and quality of our chatbot services.
    Technical Information: For diagnostic and troubleshooting purposes, we may collect non-identifiable information such as your IP address, browser type, and the time of interaction.

    <b>2. How We Use Your Information</b>

We use the information collected through our chatbot exclusively for:

    Quality Assurance and Training: Recorded chat conversations help us monitor and improve the chatbot’s performance and enhance user experience.
    Internal Analytics: Analyzing trends and usage patterns on an aggregated, non-identifiable basis to improve the chatbot’s features and accuracy.

    <b>3. No Data Sharing with Third Parties</b>

St Johns Resort Villas values your privacy. We do not sell, lease, or share any personal information or chat data collected from chatbot interactions with third parties for marketing or other purposes.
<b>4. Data Security</b>

We implement industry-standard security measures to protect the data collected by our chatbot from unauthorized access, alteration, or disclosure. Your data is stored securely and only accessible to authorized personnel involved in chatbot maintenance and improvement.
<b>5. Data Retention</b>

Chat records are retained for a limited time only as necessary to fulfill our quality control and improvement purposes. Once these needs are met, chat data is securely deleted in accordance with our data retention policy.
<b>6. Changes to This Privacy Policy</b>

We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. All changes will be posted on this page, and we encourage you to review this policy periodically.
<b>7. Contact Us</b>

If you have any questions about this Privacy Policy or our data practices, please contact us at:

    Email: [Your Email Address]
   

Thank you for choosing St Johns Resort Villas. We are committed to providing a secure and private experience as you explore your next luxury villa getaway with us.



        </div></></>
    
    );
  }
}
export default stjohnvillaPP;