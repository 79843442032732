module.exports = `<!DOCTYPE html>



<html>
    <head>
    </head>
    <body>
        why hello there...
    </body>
</html>


`;

