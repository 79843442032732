import React, { Component } from "react";
import Header from "../components/Header/index.js";
import ChatBot from "@optimlx/villachat";
import optLogo  from '../assets/img/optlogo.png'
var __html = require('./index.html.js');
var template = { __html: __html };



class stjohnvillaTC extends Component {

 

  render() {
    return (
      <><><div align="center">
        
      </div><h1 align="left"><font color="#00008B">Terms and Conditions for St Johns Resort Villas Chatbot</font></h1><div className="card">
          <p align="left">
            <b>Preamble:</b> This chatbot is provided for your convience and enjoyment to help aquiant you better with our beautiful island and our luxury villas. <br></br>
            While we make every effort to make this information as accurate and up to date as possible, we are not responsible for any errors, omissions<br></br>
            or mistakes in the content. Usage is at your own risk. This chatbot has no ability to book any reservations or activities whatsoever. <br></br>
            You must contact our management or the appropriate venue for all reservations and bookings. We reserve the right to record electronically  <br></br>
            all chats for quality control purposes so that we can improve the quality of this chatbot.  We strive to make your vacation a luxury  <br></br>
            and relaxing experience and we greatly appreciate your understanding and your business as we strive to always raise the bar to better  <br></br>
            serve you our most precious customers. This chatbot is an effort to raise that bar to improve your overall experience. <br></br>
          </p>


<b>Effective Date: August 1st, 2024</b>

Welcome to the St Johns Resort Villas Chatbot (the “Chatbot”), provided to assist you with inquiries and support regarding our luxury villa rental services. By using this Chatbot, you agree to the following Terms and Conditions. Please read these terms carefully before proceeding.

<b>1. Acceptance of Terms</b>

You MUST be 21 years of age or over to use this Chatbot!  By interacting with the Chatbot, you agree to abide by these Terms and Conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, please refrain from using the Chatbot.

<b>2. Purpose of the Chatbot</b>

The Chatbot is designed to provide general information and assist you with questions related to our luxury villa rental services. While we strive to ensure the accuracy of the information provided, it may not be exhaustive or perfectly up-to-date. For any detailed inquiries, please contact our customer support team.

<b>3. Limited Liability</b>

While we aim to maintain a seamless and functional Chatbot experience, St Johns Resort Villas is not liable for any technical issues, service interruptions, or inaccuracies in the Chatbot responses. All information provided by the Chatbot is for informational purposes only and should not be construed as binding or as a substitute for direct human support.
<b>4. Permitted Use</b>

You agree to use the Chatbot only for lawful purposes and refrain from:

    Engaging in abusive, threatening, or defamatory behavior.
    Attempting to disrupt or interfere with the Chatbot’s operation.
    Using the Chatbot for any activity that violates local, state, national, or international law.

We reserve the right to suspend or terminate your access to the Chatbot if you violate these terms.
<b>5. Data Collection and Privacy</b>

The Chatbot may collect certain information, including the content of your chats, solely for quality control and service improvement purposes, as outlined in our Privacy Policy. We do not share or sell any personal information collected through the Chatbot with third parties.

For more details on how we handle your data, please refer to our [Privacy Policy](link to Privacy Policy).

<b>6. Intellectual Property</b>

All content, features, and functionalities of the Chatbot, including text, images, design, and software, are owned exclusively by OptiMLX Inc and are licensed to ST John Resort Villas and are protected by intellectual property laws. You are granted a limited, non-exclusive, non-transferable, revocable license to use the Chatbot for personal, non-commercial use only.
<b>7. Modifications to the Chatbot and Terms</b>

St Johns Resort Villas reserves the right to update, modify, or discontinue the Chatbot at any time, as well as amend these Terms and Conditions. Any changes to these terms will be posted on this page, and we encourage you to review them periodically.
<b>8. Disclaimer of Warranties</b>

The Chatbot is provided on an “as-is” and “as-available” basis. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.
<b>9. Governing Law</b>

These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of Florida. Any disputes arising from your use of the Chatbot will be subject to the exclusive jurisdiction of the courts of Lake county Florida.

<b>10. Contact Information</b>

<b>For questions, concerns, or feedback regarding these Terms and Conditions or the Chatbot, please reach out to us:</b>

    Email: info@stjohnsresortvillas.com
    

Thank you for choosing St Johns Resort Villas to help you explore luxurious villa options. We look forward to providing a world-class experience to meet your travel needs.



        </div></></>
    
    );
  }
}
export default stjohnvillaTC;