import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-two";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/trenton.jpg'

const PageService = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="OUR PRODUCTS"
                content="OptiMLX is a builder of technology that your business simply cannot do without!"
            />
            <PageAbout
                title={'Our Products'}
                heading="Provide best <br/> Business Solutions"
                thumb={ServiceThumb}
                content="<b>OPTIMLX</b> MAKES - THE WORLD TAKES."
            />
            <Services classes="sm-top"/>
           
           
            <Funfact classes="sp-top"/>
            <CallToAction/>
            
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageService;